"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var classNames = require("classnames");
var Loader_1 = require("../Loader/Loader");
var styles = require("./ElementLoader.scss");
var ElementLoader = /** @class */ (function (_super) {
    __extends(ElementLoader, _super);
    function ElementLoader(props) {
        return _super.call(this, props) || this;
    }
    ElementLoader.prototype.render = function () {
        var _a = this.props, isLoading = _a.isLoading, text = _a.text, children = _a.children;
        return (isLoading
            ? (React.createElement("div", { className: styles.ElementLoader + " not-swipeable" }, children
                ?
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.Children }, children),
                        React.createElement("div", { className: classNames(styles.LoaderInfo, styles.LoaderMask) },
                            React.createElement("div", { className: styles.LoaderMaskContent },
                                React.createElement(Loader_1.Loader, null),
                                React.createElement("div", { className: styles.Text }, text))))
                :
                    React.createElement("div", { className: styles.LoaderInfo },
                        React.createElement(Loader_1.Loader, null),
                        React.createElement("div", { className: styles.Text }, text))))
            : children);
    };
    ElementLoader = __decorate([
        mobx_react_1.observer
    ], ElementLoader);
    return ElementLoader;
}(React.Component));
exports.ElementLoader = ElementLoader;
