"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./UploadFileInput.scss");
var classnames_1 = require("classnames");
exports.UploadFileInput = function (_a) {
    var onChangeFile = _a.onChangeFile, isDisabled = _a.isDisabled;
    return (React.createElement("div", { className: classnames_1.default(styles.UploadFileInput, isDisabled ? styles.Disabled : '') },
        React.createElement("input", { className: styles.FileInput, type: "file", accept: "image/png, image/jpeg", disabled: isDisabled, onChange: function (event) { return onChangeFile(event.target.files[0]); } }),
        "Upload another picture"));
};
