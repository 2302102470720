"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./TimerBanner.scss");
var react_countdown_now_1 = require("react-countdown-now");
var TimerInfo = function (_a) {
    var days = _a.days, hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds;
    return (React.createElement("span", null,
        days,
        " days \u00B7 ",
        hours,
        " hours \u00B7 ",
        minutes,
        " minutes ",
        React.createElement("span", { className: styles.Seconds },
            "\u00B7 ",
            seconds,
            " seconds ")));
};
var Timer = function (_a) {
    var className = _a.className, date = _a.date;
    return (React.createElement("div", { className: className },
        React.createElement(react_countdown_now_1.default, { date: date, renderer: TimerInfo })));
};
var TimerBanner = function (_a) {
    var startTime = _a.startTime, endTime = _a.endTime;
    var renderContent = function () {
        var currentTime = Date.now();
        var endDate = new Date(endTime);
        var finished = endDate.getTime() <= currentTime;
        if (finished) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.Timer }, "This competition has now ended"),
                "The winner will be announced soon"));
        }
        var startDate = new Date(startTime);
        var started = startDate.getTime() < currentTime;
        if (started) {
            return (React.createElement(React.Fragment, null,
                "The contest ends in ",
                React.createElement(Timer, { className: styles.Timer, date: endDate })));
        }
        return (React.createElement(React.Fragment, null,
            "The contest starts in ",
            React.createElement(Timer, { className: styles.Timer, date: startDate })));
    };
    return (React.createElement("section", { className: styles.TimerBanner, id: "TimeBannerSection" },
        React.createElement("h2", { className: styles.Heading }, renderContent())));
};
exports.default = TimerBanner;
