"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var LinkBack_1 = require("../../shared/components/LinkBack/LinkBack");
var Button_1 = require("../../shared/components/Button/Button");
var styles = require("./FinalScreen.scss");
exports.FinalScreenView = function (_a) {
    var matchUrl = _a.matchUrl;
    return (React.createElement("article", { className: styles.FinalScreen },
        React.createElement(LinkBack_1.default, { className: styles.Link }),
        React.createElement("div", { className: styles.Content },
            React.createElement("h1", { className: styles.Heading }, "Almost there..."),
            React.createElement("p", { className: styles.Text },
                "Now that you have downloaded your design, post it on Instagram (use ",
                React.createElement("strong", null, "#mycapisco"),
                ") and remember to follow\u00A0",
                React.createElement("a", { className: styles.LinkTag, href: "https://www.instagram.com/flokk_design/", target: "_blank" }, "@flokk_design"),
                ".",
                React.createElement("span", { className: styles.TextSmall }, "It can take up to one hour before your design shows up in the gallery.")),
            React.createElement("div", { className: styles.Buttons },
                React.createElement(Button_1.ButtonLink, { className: styles.Button, isCentered: true, url: "/gallery" }, "Go to the gallery"),
                React.createElement(Button_1.ButtonLink, { isCentered: true, variant: 'outlined', url: matchUrl + "/edit" }, "Start over")))));
};
