"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var styles = require("./Manual.scss");
var Modal_1 = require("../../shared/components/Modal/Modal");
var TermsConditions_1 = require("../../shared/components/TermsConditions/TermsConditions");
var mobx_react_1 = require("mobx-react");
var Manual = mobx_react_1.observer(function (_a) {
    var modalViewModel = _a.modalViewModel;
    return (React.createElement(React.Fragment, null,
        React.createElement("section", { id: "ManualSection", className: styles.Manual },
            React.createElement("h2", { className: styles.Title }, "It\u2019s quick and easy to enter"),
            React.createElement("ol", { className: styles.List },
                React.createElement("li", null,
                    React.createElement("span", null, "1."),
                    React.createElement("h3", null, "Start by designing your own fabric print"),
                    "You can scan in a freehand drawing, or use your favourite photograph or even use a design program - just make sure your image is a .PNG or .JPG file and it is not copyright protected (sorry no Minions!)"),
                React.createElement("li", null,
                    React.createElement("span", null, "2."),
                    React.createElement("h3", null, "It's time to create your own H\u00C5G Capisco..."),
                    "Upload your image using our special tool, play around with it and make your own unique design. Once you\u2019re happy with the design choose your base colour, from silver, white and black."),
                React.createElement("li", null,
                    React.createElement("span", null, "3."),
                    React.createElement("h3", null, "Share your design with the world #mycapisco"),
                    "Download your design in seconds and share on Instagram using the #mycapisco tag. Please make sure you post on your feed so your design shows in our gallery, you\u2019re of course welcome to promote in your stories. Your instagram profile also needs to be public. Make sure you follow @flokk_design to complete your entry.")),
            React.createElement("p", { className: styles.PrizeDescription }, "All entries with #mycapisco will appear on our gallery. The best design (as chosen by jury and popular vote) will win the H\u00C5G Capisco they designed."),
            React.createElement("div", { className: styles.LinksWrapper },
                React.createElement("button", { className: styles.Link, onClick: modalViewModel.open }, "Read more about Terms & Conditions"),
                React.createElement(react_router_dom_1.Link, { to: '/project/UV1.png/tips' }, "Join the competition")),
            React.createElement("div", { className: styles.DotTop }),
            React.createElement("div", { className: styles.DotBottom },
                React.createElement("span", null, "#mycapisco"))),
        React.createElement(Modal_1.default, { viewModel: modalViewModel },
            React.createElement(TermsConditions_1.default, null))));
});
exports.default = Manual;
