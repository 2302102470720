"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var react_swipeable_1 = require("react-swipeable");
var classNames = require("classnames");
var LinkBack_1 = require("../LinkBack/LinkBack");
var styles = require("./SwipeLayout.scss");
exports.SwipeLayout = mobx_react_1.observer(function (_a) {
    var viewModel = _a.viewModel, leftSide = _a.leftSide, rightSide = _a.rightSide, leftSideClassName = _a.leftSideClassName, _b = _a.isSwipeOff, isSwipeOff = _b === void 0 ? false : _b;
    var handleSwipe = function (event, visibleSide) {
        var path = event.path || (event.composedPath && event.composedPath());
        if (!path.some(function (element) { return element.classList && element.classList.contains('not-swipeable'); })) {
            viewModel.swipe(visibleSide);
        }
    };
    return (React.createElement(react_swipeable_1.Swipeable, { className: classNames(styles.SwipeLayout, viewModel.visibleSide === 'right' && styles.PreviewVisible), 
        // tslint:disable-next-line:no-any
        onSwipedLeft: function (event) { return handleSwipe(event.event, 'right'); }, 
        // tslint:disable-next-line:no-any
        onSwipedRight: function (event) { return handleSwipe(event.event, 'left'); }, nodeName: "article", preventDefaultTouchmoveEvent: true },
        React.createElement("div", { className: "" + classNames(styles.LeftSide, leftSideClassName, isSwipeOff && 'not-swipeable') },
            React.createElement(LinkBack_1.default, null),
            leftSide),
        React.createElement("div", { className: styles.RightSide }, rightSide)));
});
