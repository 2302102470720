"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hagFunctionUrls = new Map([
    ['production', function (environmentCode) {
            return "https://flokk-custom-hag-api-" + environmentCode + ".azurewebsites.net";
        }],
    ['development', function () { return 'http://localhost:7071'; }]
]);
var hagStorageUrls = new Map([
    ['production', function (environmentCode) { return "https://flokkcustomhag" + environmentCode + ".blob.core.windows.net"; }],
    ['development', function () { return 'http://127.0.0.1:10000/devstoreaccount1'; }]
]);
exports.getHagFunctionUrl = function (environmentCode) { return hagFunctionUrls.get(process.env.NODE_ENV)(environmentCode); };
exports.getHagStorageUrls = function (environmentCode) { return hagStorageUrls.get(process.env.NODE_ENV)(environmentCode); };
