"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var classNames = require("classnames");
var styles = require("./LinkBack.scss");
var LinkBack = function (_a) {
    var className = _a.className;
    return (React.createElement(react_router_dom_1.Link, { className: classNames(styles.LinkBack, className), to: '/' }, "Back to contest page"));
};
exports.default = LinkBack;
