"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./AboutSection.scss");
var desk = require("../../../static/images/desk.jpg");
var chair = require("../../../static/images/chair-by-window.jpg");
var warehouse = require("../../../static/images/warehouse.jpg");
var AboutSection = function () {
    return (React.createElement("section", { id: "AboutHagSection", className: styles.AboutSection },
        React.createElement("div", { className: styles.Inner },
            React.createElement("div", { className: styles.Wrapper },
                React.createElement("div", { className: styles.Txt },
                    React.createElement("h2", { className: styles.Title }, "The Original Saddle Chair"),
                    React.createElement("p", { className: styles.Description }, "The H\u00C5G Capisco designed by Peter Opsvik is a design icon that continues to withstand the test of time. Inspired by a horseback rider\u2019s posture, its pioneering saddle seat and overall unique shape, offers endless ways to sit or half stand. This encourages you to vary your position. Front ways, back ways or sideways, whatever feels most comfortable next"),
                    React.createElement("a", { className: styles.Link, href: "https://www.flokk.com/hag/products/task-chairs/hag-capisco" }, "Read more about H\u00C5G Capisco"),
                    React.createElement("a", { href: "https://store.flokk.com/global/en-gb/products/8106-STD/configure" }, "Buy H\u00C5G Capisco")),
                React.createElement("img", { className: styles.ImgTop, src: desk, alt: "A yellow H\u00C5G Capisco chair by the metal desk" })),
            React.createElement("div", { className: styles.Images },
                React.createElement("img", { className: styles.ImgBottom, src: chair, alt: "An orange H\u00C5G Capisco chair by the huge window" }),
                React.createElement("img", { className: styles.ImgBottom, src: warehouse, alt: "A bright warehouse with table and two blue H\u00C5G Capisco chairs in the middle" })))));
};
exports.default = AboutSection;
