"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./Checkbox.scss");
exports.Checkbox = function (_a) {
    var checked = _a.checked, onChange = _a.onChange, text = _a.text;
    return (React.createElement("div", { className: styles.Checkbox },
        React.createElement("label", { className: styles.CheckboxContainer },
            React.createElement("input", { className: styles.CheckboxInput, type: "checkbox", checked: checked, onChange: function () { onChange(!checked); } }),
            React.createElement("span", { className: styles.CheckboxCheckmark })),
        text));
};
