"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./Footer.scss");
var Logo_1 = require("../Logo/Logo");
var instagram = require("../../../../static/images/icons/instagram_icn.svg");
var facebook = require("../../../../static/images/icons/facebook_icn.svg");
var pinterest = require("../../../../static/images/icons/pinterest_icn.svg");
var Footer = function () {
    return (React.createElement("footer", { className: styles.Footer },
        React.createElement("div", { className: styles.FooterLogo },
            React.createElement(Logo_1.default, null)),
        React.createElement("div", null,
            React.createElement("address", null,
                "Drammensveien 145",
                React.createElement("br", null),
                "0277 Oslo",
                React.createElement("br", null),
                "Norway"),
            React.createElement("span", { className: styles.Copyright }, "Copyright \u00A92022 Flokk")),
        React.createElement("address", null,
            React.createElement("a", { href: "mailto:info-no@flokk.com" }, "info-no@flokk.com"),
            React.createElement("br", null),
            React.createElement("a", { href: "tel:+4722595900" }, "Tel: +47 22 59 59 00")),
        React.createElement("ul", { className: styles.SocialIcons },
            React.createElement("li", null,
                React.createElement("a", { className: styles.SocialIcon, href: "https://www.instagram.com/flokk_design/?hl=en", target: "_blank" },
                    React.createElement("img", { src: instagram, alt: "Instagram icon" })),
                React.createElement("a", { className: styles.SocialIcon, href: "https://www.facebook.com/WeAreFlokk/", target: "_blank" },
                    React.createElement("img", { src: facebook, alt: "Facebook icon" })),
                React.createElement("a", { className: styles.SocialIcon, href: "https://www.pinterest.com/flokkdesign/", target: "_blank" },
                    React.createElement("img", { src: pinterest, alt: "Pinterest icon" }))))));
};
exports.default = Footer;
