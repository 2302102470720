"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("react-app-polyfill/ie11");
require("react-app-polyfill/stable");
var after_1 = require("@jaredpalmer/after");
var React = require("react");
var react_dom_1 = require("react-dom");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("./app/routes");
require("normalize.css");
require("./styles/global.scss");
var GoogleAnalytics_1 = require("./app/GoogleAnalytics");
after_1.ensureReady(routes_1.default).then(function (data) { return react_dom_1.hydrate(React.createElement(react_router_dom_1.BrowserRouter, null,
    React.createElement(React.Fragment, null,
        React.createElement(after_1.After, { data: data, routes: routes_1.default, history: history, contestStartTime: data.contestStartTime, contestEndTime: data.contestEndTime, environmentCode: data.environmentCode, galleryId: data.galleryId }),
        React.createElement(GoogleAnalytics_1.default, null))), document.getElementById('root')); });
if (module.hot) {
    module.hot.accept();
}
