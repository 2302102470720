"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./Logo.scss");
var Logo = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'normal' : _b;
    return (React.createElement("a", { className: styles.Logo, href: "https://www.flokk.com/", target: "_blank" }, size === 'small'
        ?
            React.createElement("svg", { width: "76", height: "37", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("defs", null,
                    React.createElement("path", { id: "a", d: "M0 0h76v36.449H0z" })),
                React.createElement("g", { fill: "none", fillRule: "evenodd" },
                    React.createElement("mask", { id: "b", fill: "#fff" },
                        React.createElement("use", { xlinkHref: "#a" })),
                    React.createElement("path", { d: "M38 6.204A3.103 3.103 0 1 0 38 0a3.103 3.103 0 0 0 0 6.205", fill: "#DA322A", mask: "url(#b)" }),
                    React.createElement("path", { d: "M68.163 9.265c1.173.33 2.223-.124 2.91-1.036.16-.29.441-.371.766-.332.083 3.398 0 6.34-.08 9.777h-.403c-.768-3.686-3.193-7.248-6.704-8.863-1.576-.499-3.799-.666-5.212.33-2.663 1.945-3.19 5.344-3.634 8.452-.441 4.309-.647 9.404.485 13.59.686 2.195 2.505 4.016 4.685 4.556 1.735.372 3.515-.041 4.605-1.449 1.33-2.197.564-5.47.806-7.917l-.242-.123h-3.513c.16-.248-.08-.454.12-.66h13.21c.078.206 0 .412.038.62-1.171.083-2.462-.082-3.514.123-.12 3.316-.08 6.133-.202 9.365-.727.25-.77-.705-1.374-.913-3.23-.868-5.694 1.823-8.965 1.657-5.492.042-10.217-3.976-12.194-8.905-2.061-5.72-.487-12.596 3.995-16.655 3.152-2.57 7.432-3.977 11.552-2.57l2.865.953M39.1 26.103l-3.432-9.339c-1.173 3.11-2.383 6.226-3.391 9.34H39.1zM0 35.723h4.035V9.83H.503v-.673h12.818v.673h-3.28v11.261h8.553V9.831h-3.052v-.674h12.567v.673h-3.332v25.893h2.774L38.09 7.755h.643l10.771 27.968h3.23v.726H39.563v-.726h3.132l-3.355-8.873h-7.346l-3.28 8.873h3.434v.726h-16.86v-.726h3.307V21.896h-8.552v13.827h3.483v.726H0v-.726z", fill: "#1A1919", mask: "url(#b)" })))
        :
            React.createElement("svg", { width: "103", height: "78", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("g", { fill: "none", fillRule: "evenodd" },
                    React.createElement("mask", { id: "b", fill: "#fff" },
                        React.createElement("use", { xlinkHref: "#a" })),
                    React.createElement("path", { d: "M51.915 8.855a4.24 4.24 0 1 0 .008-8.481 4.24 4.24 0 0 0-.008 8.481z", fill: "#EA2D30", fillRule: "nonzero" }),
                    React.createElement("path", { d: "M92.357 13.149a3.607 3.607 0 0 0 3.927-1.366.955.955 0 0 1 1.034-.434c.113 4.47 0 8.338-.106 12.862h-.547c-1.033-4.849-4.308-9.538-9.055-11.662-2.124-.657-5.127-.876-7.032.434-3.596 2.561-4.305 7.032-4.905 11.118-.596 5.66-.872 12.375.653 17.88a9.081 9.081 0 0 0 6.323 5.995c2.343.49 4.746-.053 6.218-1.886 1.796-2.89.754-7.195 1.086-10.417l-.328-.163H84.89c.215-.324-.11-.596.159-.867h17.83c.106.271 0 .543.053.815-1.58.11-3.324-.106-4.742.162-.163 4.361-.11 8.07-.276 12.322-.98.328-1.037-.928-1.852-1.2-4.362-1.131-7.686 2.396-12.1 2.177-7.414.057-13.79-5.229-16.457-11.714-2.781-7.546-.657-16.571 5.391-21.913 4.256-3.396 10.032-5.23 15.59-3.396l3.87 1.253zM0 47.199h5.448V13.205H.68v-.89h17.306v.89h-4.429v14.79h11.549v-14.79h-4.12v-.89h16.978v.89H33.45V47.2h3.747l14.228-36.725h.871l14.54 36.725h4.362v.954H53.413V47.2h4.23l-4.528-11.65h-9.92l-4.429 11.65h4.622v.954H20.642V47.2h4.463V29.05H13.556V47.2h4.705v.954H0V47.2zm52.79-12.632l-4.633-12.262c-1.584 4.083-3.218 8.176-4.58 12.262h9.213z", fill: "#231F20", fillRule: "nonzero" }),
                    React.createElement("path", { d: "M20.064 48.153h31.074v35.839H20.064z" }),
                    React.createElement("path", { d: "M5.746 60.645h4.44c2.548 0 3.838 1.181 3.838 3.143a2.962 2.962 0 0 1-2.071 2.966c1.343.2 2.6 1.132 2.6 2.98 0 2.147-1.691 3.637-4.128 3.637H5.746V60.645zm1.562 1.23v4.31h2.38c1.767 0 2.747-.755 2.747-2.265 0-1.365-.818-2.037-2.546-2.037l-2.58-.008zm0 10.255h2.566c2.38 0 3.09-1.132 3.09-2.38 0-1.69-1.29-2.328-3.147-2.328H7.308v4.708zM17.653 77.005l1.51-3.671-3.396-8.546h1.588s2.264 5.8 2.566 6.746h.034c.271-.947 2.509-6.746 2.509-6.746h1.475l-4.72 12.217h-1.566zM30.127 73.371V60.645h7.47v1.272h-5.905v4.252h5.509v1.275h-5.509v5.927zM39.763 60.645h1.509v12.726h-1.51zM51.798 69.078c0 2.603-1.51 4.509-4.127 4.509-2.619 0-4.128-1.887-4.128-4.51 0-2.621 1.51-4.527 4.128-4.527 2.618 0 4.127 1.928 4.127 4.528zm-1.585 0c0-1.8-.833-3.29-2.542-3.29-1.71 0-2.547 1.49-2.547 3.29s.837 3.294 2.547 3.294c1.709 0 2.542-1.49 2.542-3.294zM56.593 68.878l-1.015.92v3.566H54.05V60.645h1.528v7.546l3.69-3.418h1.8l-3.343 3.14 3.943 5.455h-1.853zM65.724 68.878l-1 .92v3.566h-1.528V60.645h1.528v7.546l3.69-3.418h1.8l-3.362 3.14 3.943 5.455h-1.853z", fill: "#231F20", fillRule: "nonzero" })))));
};
exports.default = Logo;
