"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var react_konva_1 = require("react-konva");
var classNames = require("classnames");
var ResponsiveStage_1 = require("../../shared/components/responsiveStage/ResponsiveStage");
var styles = require("./ImageEditor.scss");
var ImageEditorViewModel_1 = require("./ImageEditorViewModel");
var CheckboxIcon_1 = require("../../shared/components/checkboxIcon/CheckboxIcon");
var UploadFileInput_1 = require("../../shared/components/UploadFileInput/UploadFileInput");
var Button_1 = require("../../shared/components/Button/Button");
var ImageEditorView = /** @class */ (function (_super) {
    __extends(ImageEditorView, _super);
    function ImageEditorView(props) {
        var _this = _super.call(this, props) || this;
        _this.viewModel = new ImageEditorViewModel_1.ImageEditorViewModel();
        return _this;
    }
    ImageEditorView.prototype.render = function () {
        var _this = this;
        var _a = this.props, patternImage = _a.patternImage, cutOutImage = _a.cutOutImage, ratioWidth = _a.ratioWidth, ratioHeight = _a.ratioHeight, virtualTranslateX = _a.virtualTranslateX, virtualTranslateY = _a.virtualTranslateY, isPatternApplied = _a.isPatternApplied, isPreviewRenderingInProgress = _a.isPreviewRenderingInProgress, canUpdatePreview = _a.canUpdatePreview, applyPattern = _a.applyPattern, updatePreview = _a.updatePreview, changeImagePosition = _a.changeImagePosition, updateScale = _a.updateScale, scale = _a.scale, reset = _a.reset, onChangeFile = _a.onChangeFile, isCutOutVisible = _a.isCutOutVisible, setCutOutVisible = _a.setCutOutVisible, setVisibleSide = _a.setVisibleSide;
        return (React.createElement("div", { className: styles.ImageEditor },
            isPatternApplied
                ? React.createElement("h1", { className: styles.Heading }, "Scale & crop your picture")
                : React.createElement("h1", { className: styles.Heading }, "Click on button below to preview your H\u00C5G Capisco"),
            React.createElement("div", { className: styles.EditorContainer },
                React.createElement("div", { className: styles.EditorRow },
                    React.createElement("div", { className: styles.CanvasContainer + " " + (isPatternApplied && styles.CanvasMove) + " not-swipeable" },
                        React.createElement(ResponsiveStage_1.ResponsiveStage, { ref: function (ref) { return _this.stage = ref; }, ratioWidth: ratioWidth, ratioHeight: ratioHeight }, function (stageViewModel) {
                            return React.createElement(react_konva_1.Layer, { offsetX: -stageViewModel.stageOffsetX, offsetY: -stageViewModel.stageOffsetY },
                                cutOutImage && isCutOutVisible && isPatternApplied && React.createElement(react_konva_1.Image // TODO new add computed
                                , __assign({ offsetX: stageViewModel.stageOffsetX, offsetY: stageViewModel.stageOffsetY, image: cutOutImage }, stageViewModel.getImageSize(cutOutImage, 1))),
                                patternImage && _this.renderPatternImage(stageViewModel));
                        })),
                    isPatternApplied &&
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.Checkboxes },
                                React.createElement(CheckboxIcon_1.CheckboxIcon, { icon: CheckboxIcon_1.Icon.eye, checkedText: 'Hide cut out', uncheckedText: 'Show cut out', checked: isCutOutVisible, onChange: setCutOutVisible })),
                            !canUpdatePreview &&
                                React.createElement("button", { className: styles.PreviewButton, onClick: function () { return setVisibleSide('right'); } }, "Preview")))),
            isPatternApplied
                ?
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: styles.ScaleInputContainer + " not-swipeable" },
                            React.createElement("button", { className: classNames(styles.ScaleButton, styles.ScaleButtonDecrease), onClick: function () { return scale - 0.25 >= 1 && updateScale(scale - 0.25); } }, "\u2212"),
                            React.createElement("input", { className: styles.ScaleInput, type: "range", min: 1, max: 5, value: scale, step: '0.25', onChange: function (event) {
                                    var newScale = +event.target.value;
                                    var imageSize = _this.stage.viewModel.getImageSize(patternImage, newScale);
                                    var newPosition = _this.viewModel.getImagePosition(imageSize, virtualTranslateX, virtualTranslateY);
                                    _this.viewModel.updateVirtualTranslatePosition(imageSize, _this.stage.viewModel.getImagePositionWithRestriction(patternImage, newPosition, newScale), changeImagePosition);
                                    updateScale(newScale);
                                } }),
                            React.createElement("button", { className: classNames(styles.ScaleButton, styles.ScaleButtonIncrease), onClick: function () { return scale + 0.25 <= 5 && updateScale(scale + 0.25); } }, "+")),
                        React.createElement("div", { className: styles.Buttons },
                            React.createElement(Button_1.Button, { onClick: reset }, "Discard changes"),
                            React.createElement(Button_1.Button, { onClick: updatePreview, isDisabled: !canUpdatePreview }, "Apply changes")))
                : React.createElement(Button_1.Button, { className: styles.Button, onClick: applyPattern, isDisabled: isPreviewRenderingInProgress }, "Apply & preview"),
            React.createElement(UploadFileInput_1.UploadFileInput, { isDisabled: isPreviewRenderingInProgress, onChangeFile: onChangeFile })));
    };
    ImageEditorView.prototype.renderPatternImage = function (stageViewModel) {
        var _this = this;
        var _a = this.props, patternImage = _a.patternImage, changeImagePosition = _a.changeImagePosition, virtualTranslateX = _a.virtualTranslateX, virtualTranslateY = _a.virtualTranslateY, isCutOutVisible = _a.isCutOutVisible, scale = _a.scale, isPatternApplied = _a.isPatternApplied;
        var imageSize = stageViewModel.getImageSize(patternImage, scale);
        var newPosition = this.viewModel.getImagePosition(imageSize, virtualTranslateX, virtualTranslateY);
        return (React.createElement(react_konva_1.Image, __assign({ opacity: isCutOutVisible && isPatternApplied ? 0.5 : 1, image: patternImage, draggable: isPatternApplied, globalCompositeOperation: "multiply", offset: stageViewModel.getImageOffset(patternImage, scale) }, stageViewModel.getImagePositionWithRestriction(patternImage, newPosition, scale), imageSize, { dragBoundFunc: function (pos) {
                pos.x = pos.x - stageViewModel.stageOffsetX;
                pos.y = pos.y - stageViewModel.stageOffsetY;
                var newPositionWithRestriction = stageViewModel.getImagePositionWithRestriction(patternImage, pos, scale);
                return {
                    x: newPositionWithRestriction.x + stageViewModel.stageOffsetX,
                    y: newPositionWithRestriction.y + stageViewModel.stageOffsetY
                };
            }, onDragEnd: function (evt) {
                return _this.viewModel.updateVirtualTranslatePosition(imageSize, evt.target.position(), changeImagePosition);
            } })));
    };
    ImageEditorView = __decorate([
        mobx_react_1.observer
    ], ImageEditorView);
    return ImageEditorView;
}(React.Component));
exports.ImageEditorView = ImageEditorView;
