"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var classNames = require("classnames");
var styles = require("./Button.scss");
var variantClasses = {
    'outlined': styles.ButtonOutlined,
    'contained': '',
};
var sizeClasses = {
    'small': styles.ButtonSmall,
    'medium': styles.ButtonMedium,
    'large': styles.ButtonLarge
};
exports.ButtonLink = function (_a) {
    var isCentered = _a.isCentered, className = _a.className, _b = _a.variant, variant = _b === void 0 ? 'contained' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, url = _a.url, children = _a.children, onClick = _a.onClick;
    return (React.createElement("div", { className: isCentered ? styles.ButtonCenter : undefined },
        React.createElement(react_router_dom_1.Link, { onClick: onClick, className: classNames(className, styles.Button, variantClasses[variant], sizeClasses[size]), to: url }, children)));
};
exports.ButtonALink = function (_a) {
    var isCentered = _a.isCentered, className = _a.className, _b = _a.variant, variant = _b === void 0 ? 'contained' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, href = _a.href, target = _a.target, children = _a.children, onClick = _a.onClick;
    return (React.createElement("div", { className: isCentered ? styles.ButtonCenter : undefined },
        React.createElement("a", { onClick: onClick, className: classNames(className, styles.Button, variantClasses[variant], sizeClasses[size]), target: target, href: href }, children)));
};
exports.Button = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.variant, variant = _b === void 0 ? 'contained' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, isDisabled = _a.isDisabled, onClick = _a.onClick;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: classNames(className, styles.Button, variantClasses[variant], sizeClasses[size], isDisabled && styles.ButtonDisabled), onClick: onClick, disabled: isDisabled }, children)));
};
exports.ButtonLoader = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.variant, variant = _b === void 0 ? 'contained' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: classNames(className, styles.Button, variantClasses[variant], sizeClasses[size], styles.ButtonDisabled), disabled: true },
            React.createElement("span", { className: styles.ButtonLoader }),
            " ",
            children)));
};
