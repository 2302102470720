"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var classNames = require("classnames");
var ImageEditorView_1 = require("./imageEditor/ImageEditorView");
var PreviewView_1 = require("./preview/PreviewView");
var ImageUploader_1 = require("../shared/components/ImageUploader");
var SwipeLayout_1 = require("../shared/components/SwipeLayout/SwipeLayout");
var ElementLoader_1 = require("../shared/components/loaders/ElementLoader/ElementLoader");
var styles = require("./ProjectView.scss");
var Button_1 = require("../shared/components/Button/Button");
var FootbasesView_1 = require("./footbases/FootbasesView");
exports.ProjectEditorView = mobx_react_1.observer(function (props) {
    var viewModel = props.viewModel, matchUrl = props.matchUrl;
    var leftSide = function () {
        return (viewModel.isUserImageLoaded
            ? (React.createElement(ImageEditorView_1.ImageEditorView, { patternImage: viewModel.userImage, cutOutImage: viewModel.previewCutOut, changeImagePosition: viewModel.translateUserImage, ratioWidth: viewModel.ratioWidth, ratioHeight: viewModel.ratioHeight, isPatternApplied: viewModel.isPatternApplied, isPreviewRenderingInProgress: viewModel.isPreviewRenderingInProgress, canUpdatePreview: viewModel.canUpdatePreview, applyPattern: viewModel.applyPattern, updatePreview: viewModel.updatePreview, updateScale: viewModel.updateScale, reset: viewModel.resetTransforms, scale: viewModel.userImageScale, virtualTranslateX: viewModel.virtualTranslateX, virtualTranslateY: viewModel.virtualTranslateY, isCutOutVisible: viewModel.isCutOutVisible, setCutOutVisible: viewModel.setCutOutVisible, onChangeFile: function (file) { return viewModel.openUserImageFile(file); }, setVisibleSide: viewModel.setVisibleSide }))
            : React.createElement(ImageUploader_1.ImageUploader, { isLoading: viewModel.isUploadInProgress, onChangeFile: function (file) { return viewModel.openUserImageFile(file); } }));
    };
    var rightSide = function () {
        return (React.createElement(ElementLoader_1.ElementLoader, { isLoading: viewModel.isPreviewRenderingInProgress, text: "We are creating a preview of your H\u00C5G Capisco..." },
            React.createElement("button", { className: classNames(styles.LinkBack, viewModel.isPreviewRenderingInProgress && styles.PreviewElementHide), onClick: function () { return viewModel.setVisibleSide('left'); } }, viewModel.isUserImageLoaded ? 'Back to editor' : 'Back to uploader'),
            React.createElement("div", { className: classNames(styles.PreviewContainer, !viewModel.isPatternApplied && styles.PreviewInit) },
                React.createElement(PreviewView_1.PreviewView, { previewImageSrc: viewModel.previewImageSrc })),
            viewModel.isPatternApplied &&
                React.createElement("div", { className: classNames(styles.PreviewInfo, viewModel.isPreviewRenderingInProgress && styles.PreviewElementHide) },
                    React.createElement("div", { className: styles.FootbasesContainer },
                        React.createElement(FootbasesView_1.FootbasesView, { footbasesViewModel: viewModel.footbasesViewModel })),
                    React.createElement("div", { className: styles.PreviewText }, "Happy with the result?"),
                    React.createElement(Button_1.ButtonLink, { variant: "outlined", onClick: viewModel.generate, url: matchUrl + "/download" }, "Download & Share"))));
    };
    return (React.createElement(SwipeLayout_1.SwipeLayout, { viewModel: viewModel.swipeLayoutViewModel, leftSide: leftSide(), rightSide: rightSide(), isSwipeOff: viewModel.canUpdatePreview }));
});
