"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var ResponsiveStageViewModel = /** @class */ (function () {
    function ResponsiveStageViewModel(ratioWidth, ratioHeight) {
        this.ratioWidth = ratioWidth;
        this.ratioHeight = ratioHeight;
    }
    Object.defineProperty(ResponsiveStageViewModel.prototype, "stageOffsetX", {
        get: function () {
            return this.stageWidth / 2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResponsiveStageViewModel.prototype, "stageOffsetY", {
        get: function () {
            return this.stageHeight / 2;
        },
        enumerable: true,
        configurable: true
    });
    ResponsiveStageViewModel.prototype.getStageSize = function (containerWidth) {
        var ratio = Math.min(this.ratioWidth, this.ratioHeight);
        var stageWidth = containerWidth / ratio * this.ratioWidth;
        var stageHeight = containerWidth / ratio * this.ratioHeight;
        return { width: stageWidth, height: stageHeight };
    };
    ResponsiveStageViewModel.prototype.setStageSize = function (width, height) {
        this.stageWidth = width;
        this.stageHeight = height;
    };
    ResponsiveStageViewModel.prototype.getImageSize = function (image, scale) {
        if (!image) {
            return;
        }
        var ratio = Math.max(this.stageWidth / image.width, this.stageHeight / image.height);
        return { width: image.width * scale * ratio, height: image.height * scale * ratio };
    };
    ResponsiveStageViewModel.prototype.getImagePositionWithRestriction = function (image, newPosition, scale) {
        var _a = this.getImageSize(image, scale), width = _a.width, height = _a.height;
        var x = newPosition.x, y = newPosition.y;
        var newX = x, newY = y;
        var maxXPosition = (width - this.stageWidth) / 2;
        var maxYPosition = (height - this.stageHeight) / 2;
        var minXPosition = -maxXPosition;
        var minYPosition = -maxYPosition;
        if (x >= maxXPosition) {
            newX = maxXPosition;
        }
        if (x <= minXPosition) {
            newX = minXPosition;
        }
        if (y >= maxYPosition) {
            newY = maxYPosition;
        }
        if (y <= minYPosition) {
            newY = minYPosition;
        }
        return { x: newX, y: newY };
    };
    ResponsiveStageViewModel.prototype.getImageOffset = function (image, scale) {
        var _a = this.getImageSize(image, scale), width = _a.width, height = _a.height;
        return { x: width / 2, y: height / 2 };
    };
    __decorate([
        mobx_1.observable
    ], ResponsiveStageViewModel.prototype, "stageWidth", void 0);
    __decorate([
        mobx_1.observable
    ], ResponsiveStageViewModel.prototype, "stageHeight", void 0);
    __decorate([
        mobx_1.observable
    ], ResponsiveStageViewModel.prototype, "ratioWidth", void 0);
    __decorate([
        mobx_1.observable
    ], ResponsiveStageViewModel.prototype, "ratioHeight", void 0);
    __decorate([
        mobx_1.computed
    ], ResponsiveStageViewModel.prototype, "stageOffsetX", null);
    __decorate([
        mobx_1.computed
    ], ResponsiveStageViewModel.prototype, "stageOffsetY", null);
    __decorate([
        mobx_1.action.bound
    ], ResponsiveStageViewModel.prototype, "getStageSize", null);
    __decorate([
        mobx_1.action.bound
    ], ResponsiveStageViewModel.prototype, "setStageSize", null);
    __decorate([
        mobx_1.action.bound
    ], ResponsiveStageViewModel.prototype, "getImageSize", null);
    __decorate([
        mobx_1.action.bound
    ], ResponsiveStageViewModel.prototype, "getImagePositionWithRestriction", null);
    __decorate([
        mobx_1.action.bound
    ], ResponsiveStageViewModel.prototype, "getImageOffset", null);
    return ResponsiveStageViewModel;
}());
exports.ResponsiveStageViewModel = ResponsiveStageViewModel;
