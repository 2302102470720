"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var react_router_hash_link_1 = require("react-router-hash-link");
var Navigation_1 = require("../../shared/components/Navigation/Navigation");
var styles = require("./Header.scss");
var arrow = require("../../../static/images/icons/arrow_icn.svg");
var Header = function (_a) {
    var areImagesLoaded = _a.areImagesLoaded;
    return (React.createElement("header", { className: styles.Header },
        React.createElement(Navigation_1.default, { isHomepage: true }),
        React.createElement("div", { className: styles.Content },
            React.createElement("div", { className: styles.Txt },
                React.createElement("h1", { className: styles.Heading },
                    "Win your own designed H\u00C5G Capisco ",
                    React.createElement("strong", { className: styles.HeadingStrong }, "Share your style")),
                React.createElement("p", { className: styles.Paragraph },
                    "Enter #mycapisco and share your style on Instagram for chance to win a H\u00C5G Capisco, designed by you!",
                    React.createElement("br", null),
                    React.createElement("strong", null, "This event ended on December 2, 2022.")),
                React.createElement(react_router_hash_link_1.HashLink, { to: "#mainContent", smooth: true },
                    React.createElement("img", { className: styles.ArrowIco, src: arrow, alt: "Arrow down icon" }))),
            React.createElement("div", { className: styles.Img }, areImagesLoaded &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(styles.Chair, styles.ChairFirst) }),
                    React.createElement("div", { className: classNames(styles.Chair, styles.ChairSecond) }),
                    React.createElement("div", { className: classNames(styles.Chair, styles.ChairThird) }),
                    React.createElement("div", { className: classNames(styles.Chair, styles.ChairFourth) }),
                    React.createElement("div", { className: styles.Dot }))))));
};
exports.default = Header;
