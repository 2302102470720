"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./TermsConditions.scss");
var TermsConditions = function () {
    return (React.createElement("article", { className: styles.TermsConditions },
        React.createElement("h2", { className: styles.Heading }, "Terms &\u00A0Conditions"),
        React.createElement("ol", { className: styles.ListTopLevel },
            React.createElement("li", null,
                React.createElement("h3", { className: styles.ListItemHeading }, "Introduction"),
                React.createElement("ol", { className: styles.ListSecondLevel },
                    React.createElement("li", null, "The promoter is H\u00C5G, a Flokk brand, who can be contacted at Drammensveien 145, 0277 Oslo, Norway."),
                    React.createElement("li", null, "By entering this Competition, you agree to these Terms and Conditions (the \u201CTerms\u201D) and any decisions reached by Flokk, which are final and binding in all matters related to this Competition."),
                    React.createElement("li", null, "The Competition will run from 8:00am on 21st October 2022 until 23:59pm on 2nd December 2022 on\u00A0(the \u201CCompetition Period\u201D)."))),
            React.createElement("li", null,
                React.createElement("h3", { className: styles.ListItemHeading }, "How does the Competition work?"),
                React.createElement("ol", { className: styles.ListSecondLevel },
                    React.createElement("li", null, "Participants must be aged 16 or over."),
                    React.createElement("li", null, "This competition is not open to directors, members, partners, employees, agents or consultants of Flokk or\u00A0 any of Flokk's brands, any person who directly or indirectly controls or is controlled by Flokk, or any supplier of goods or services in connection with the Competition, or the spouses, life partners, business partners or immediate family members."),
                    React.createElement("li", null, "No purchase necessary."),
                    React.createElement("li", null,
                        "To enter the competition, entrants must:",
                        React.createElement("ol", { className: styles.ListThirdLevel },
                            React.createElement("li", null,
                                "Use the design tools located on ",
                                React.createElement("a", { href: "https://mycapisco.flokk.com" }, "the competition website"),
                                "."),
                            React.createElement("li", null, "Post their design to their personal Instagram page utilising the #MyCapisco Hashtag."),
                            React.createElement("li", null, "Entrants must make sure that their Instagram page is public."))),
                    React.createElement("li", null, "There are no other specifications for the design and it can be made using any method e.g. drawing, painting, use of digital software, collage etc."),
                    React.createElement("li", null, "Entrants acknowledge and warrant that their design is their own intellectual creation and has not been copied or taken in whole or in part from any other source."),
                    React.createElement("li", null, "Customers can enter the Competition up to five times. If a customer submits more than five Entries, only the first five will be entered into the Competition."),
                    React.createElement("li", null, "Any Entries posted outside of the Competition Period shall not constitute a valid Entry and will not be entered into the Competition."),
                    React.createElement("li", null, "The decision of Flokk regarding any aspect of the Competition is final and binding and no correspondence will be entered into about it."),
                    React.createElement("li", null, "Prizes are non-transferable, non-exchangeable, and have a cash value of zero. Prize is for personal use only and not for resale."))),
            React.createElement("li", null,
                React.createElement("h3", { className: styles.ListItemHeading }, "Prize"),
                React.createElement("ol", { className: styles.ListSecondLevel },
                    React.createElement("li", null, "The winner will have their design made into a H\u00C5G Capisco chair."),
                    React.createElement("li", null, "The first place winner will be chosen as a result of a combination between public votes & a Flokk Jury (Design manager Flokk, Brand manager Flokk, Textile Designer Flokk, Art Director H\u00C5G). The jury will choose the winner based on creativity, aesthetics, applicability and popular vote."),
                    React.createElement("li", null, "The winning design will be made to a limited edition H\u00C5G Capisco collection available for other customers to buy."),
                    React.createElement("li", null, "The prize winners will be contacted by a representative of H\u00C5G by the same method that they used to enter the Competition or by telephone within one (1) week of the end of the Competition Period (the \u201CWinner Notification\u201D)."),
                    React.createElement("li", null, "The prize winner has seven (7) days to respond to the Winner Notifications and claim their Prize. In the event that the winner fails to respond to the Winner Notification, another winner will be selected from all valid Entries on the same criteria as set out in section 3(c)."),
                    React.createElement("li", null, "The Prize will then be put into production."),
                    React.createElement("li", null, "The Prize will be made available at a Flokk dealer or partner closest to a location specified by the winners. The winner will receive the chair within 6 months of the design being finalised and H\u00C5G notifying the winner that the Prize is being made."))),
            React.createElement("li", null,
                React.createElement("h3", { className: styles.ListItemHeading }, "Intellectual Property Rights and Use of Submissions"),
                React.createElement("ol", { className: styles.ListSecondLevel },
                    React.createElement("li", null, "By submitting an Entry to the Competition, the participant grants Flokk (and H\u00C5G) the worldwide right to use the Entry in relation to the Competition, for the purposes of manufacturing the Prize and, manufacturing and distributing the Entry for commercial purposes for unlimited period time (which shall include sharing the Entry with trusted third parties), and/or in relation to the advertising and/or marketing of H\u00C5G\u2019s products and brand. Such use may include, without limitation, reproducing, copying, translating, adapting, publishing or making available your Entry i) in hardcopy, softcopy, or CAD formats, ii) on H\u00C5G\u2019s (or Flokk\u2019s) websites, iii) on any social media account controlled by H\u00C5G (or Flokk); and iv) in H\u00C5G\u2019s (or Flokk\u2019s) other advertising and/or marketing materials. The rights granted in this paragraph are perpetual (i.e. they last, forever) and royalty-free (i.e. the participant will not be entitled to any compensation or\u00A0other payments from H\u00C5G or Flokk)."),
                    React.createElement("li", null, "All participants irrevocably waive all moral rights in their Entries to which they are entitled."),
                    React.createElement("li", null, "The Competition will run from 8:00am on 21st October 2022 until 23:59pm on 2nd December 2022 on\u00A0(the \u201CCompetition Period\u201D)."),
                    React.createElement("li", null, "By submitting an Entry to the Competition, participants agree that any such design, materials, photos or, images will not infringe the intellectual property rights or other rights of any third party."))),
            React.createElement("li", null,
                React.createElement("h3", { className: styles.ListItemHeading }, "General"),
                React.createElement("ol", { className: styles.ListSecondLevel },
                    React.createElement("li", null, "Flokk reserves the right to amend the rules of the Competition, to withdraw the Competition at any time if\u00A0circumstances beyond its control make this unavoidable, or to cancel the Prize in the event of non-compliance with these Terms."),
                    React.createElement("li", null, "The winner may be required to participate in reasonable post-event publicity, including the publication of\u00A0his/her name and geographic location."),
                    React.createElement("li", null, "The name of the winner will be made available upon request."),
                    React.createElement("li", null, "Flokk may contact participants using the social media platform that they used to enter the Competition or\u00A0the email address supplied in Entry for administrative purposes relating to this Competition."),
                    React.createElement("li", null, "Subject to clause 8(c) above, Flokk will keep your personal information safe and will not disclose it to\u00A0anyone else, and will use your personal information in accordance with the privacy policy available on\u00A0its website."),
                    React.createElement("li", null, "Customers are encouraged to read these Terms in conjunction with Flokk\u2019s online terms and conditions at\u00A0www.flokk.com."),
                    React.createElement("li", null, "Prize may become unavailable due to events outside of Flokk\u2019s control. In such an event, Flokk will endeavour to provide a substitution for the Prize. Flokk cannot guarantee the availability of the Prize. Prize\u00A0cannot be exchanged for cash."),
                    React.createElement("li", null, "Flokk is not responsible for any costs associated with the Prize that are not included in these terms."),
                    React.createElement("li", null, "With the exception of liability which cannot be excluded by law, Flokk is not liable for, and you release Flokk from, liabilities relating to any direct or indirect loss or damage which is suffered as a result of\u00A0entering the Competition, accepting or using the Prize."),
                    React.createElement("li", null, "If you require any help or have any enquiries relating to these terms, please contact Flokk at the address above."),
                    React.createElement("li", null, "This Competition is run by Flokk, it has no affiliation with Instagram and they have no liability whatsoever for the running of this Competition. Any complaint about the Competition should be directed to Flokk and\u00A0not Instagram."))))));
};
exports.default = TermsConditions;
