"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var react_dropzone_1 = require("react-dropzone");
var classnames_1 = require("classnames");
var styles = require("./ImageUploader.scss");
var ElementLoader_1 = require("./loaders/ElementLoader/ElementLoader");
exports.ImageUploader = mobx_react_1.observer(function (_a) {
    var isLoading = _a.isLoading, onChangeFile = _a.onChangeFile;
    return (React.createElement("div", { className: styles.ImageUploader },
        React.createElement("h1", { className: styles.Heading }, "Upload your picture"),
        React.createElement(react_dropzone_1.default, { accept: "image/png, image/jpeg", multiple: false, onDrop: function (acceptedFiles) { return onChangeFile(acceptedFiles[0]); } }, function (_a) {
            var _b;
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return (React.createElement("div", __assign({ className: classnames_1.default(styles.DragAndDrop, (_b = {}, _b[styles.Loading] = isLoading, _b)) }, getRootProps()), !isLoading
                ? React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles.Text },
                        React.createElement("span", { className: styles.TextWide },
                            "Drag and drop",
                            React.createElement("br", null),
                            "your picture here or"),
                        React.createElement("span", { className: styles.TextMobile }, "Select an image file")),
                    React.createElement("input", __assign({ id: "img-upload" }, getInputProps())),
                    React.createElement("label", { htmlFor: "img-ipload", className: styles.InputLabel }, "Select a file"))
                : React.createElement(ElementLoader_1.ElementLoader, { isLoading: isLoading, text: "Processing your picture..." })));
        }),
        React.createElement("p", { className: styles.InputInfo }, "Only JPG or PNG file formats are supported")));
});
