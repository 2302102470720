"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var Modal_1 = require("../../shared/components/Modal/Modal");
var LinkBack_1 = require("../../shared/components/LinkBack/LinkBack");
var TermsConditions_1 = require("../../shared/components/TermsConditions/TermsConditions");
var Button_1 = require("../../shared/components/Button/Button");
var styles = require("./TipsListView.scss");
var mobx_react_1 = require("mobx-react");
var SliderIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className, width: "13", height: "22", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13 1.314L11.598 0 0 10.833l11.598 10.834L13 20.36 2.81 10.833z", fill: "#174E42" })));
};
exports.TipsListView = mobx_react_1.observer(function (props) {
    var tipsListViewModel = props.tipsListViewModel;
    return (React.createElement("div", { className: styles.TipsContainer },
        React.createElement(LinkBack_1.default, { className: styles.LinkBack }),
        tipsListViewModel.tipsList.length > 0 && React.createElement("div", { className: styles.TipContainer },
            React.createElement("p", { className: styles.TextUppercase }, "Before you start..."),
            React.createElement("h1", { className: styles.TipsHeader }, "We have a few tips for you!"),
            React.createElement("div", { className: styles.NavigationContainer },
                React.createElement("button", { className: classnames_1.default(styles.NavigationButton, tipsListViewModel.isFirstTip ? styles.NavigationButtonHidden : ''), onClick: function () { return tipsListViewModel.setCurrentIndex(tipsListViewModel.currentIndex - 1); } },
                    React.createElement(SliderIcon, null)),
                React.createElement("div", { className: styles.TipImageContainer },
                    React.createElement("img", { src: tipsListViewModel.galleryItems[tipsListViewModel.currentIndex] })),
                React.createElement("button", { className: classnames_1.default(styles.NavigationButton, tipsListViewModel.isLastTip ? styles.NavigationButtonHidden : ''), onClick: function () { return tipsListViewModel.setCurrentIndex(tipsListViewModel.currentIndex + 1); } },
                    React.createElement(SliderIcon, { className: styles.IconNext }))),
            React.createElement("p", { className: styles.TipsCounter },
                React.createElement("strong", null, tipsListViewModel.currentIndex + 1),
                "/",
                tipsListViewModel.tipsList.length),
            React.createElement("div", { className: styles.TipDescriptionContainer },
                React.createElement("p", { className: styles.TipDescription }, tipsListViewModel.tipsList[tipsListViewModel.currentIndex].description),
                React.createElement(Button_1.ButtonLink, { isCentered: true, url: 'edit' }, tipsListViewModel.isLastTip ? 'Get started' : 'Skip and get started')),
            React.createElement("button", { className: styles.Link, onClick: tipsListViewModel.termsconditionsModal.open }, "Terms & Conditions")),
        React.createElement(Modal_1.default, { viewModel: tipsListViewModel.termsconditionsModal },
            React.createElement(TermsConditions_1.default, null))));
});
