"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var react_router_1 = require("react-router");
var HagFunctionService_1 = require("../shared/HagFunctionService");
var ProjectViewModel_1 = require("./ProjectViewModel");
var ProjectEditorView_1 = require("./ProjectEditorView");
var ProjectDownloadView_1 = require("./ProjectDownloadView");
var ImageLoader_1 = require("../shared/ImageLoader");
var TipsListView_1 = require("./tips/TipsListView");
var FinalScreenView_1 = require("./finalScreen/FinalScreenView");
var SwipeLayoutViewModel_1 = require("../shared/components/SwipeLayout/SwipeLayoutViewModel");
var ModalViewModel_1 = require("../shared/components/Modal/ModalViewModel");
var FootbasesViewModel_1 = require("./footbases/FootbasesViewModel");
var TipsListViewModel_1 = require("./tips/TipsListViewModel");
var resources_1 = require("../../resources");
var ProjectView = /** @class */ (function (_super) {
    __extends(ProjectView, _super);
    function ProjectView(props) {
        var _this = _super.call(this, props) || this;
        _this.componentWillReceiveProps = function (props) {
            _this.functionService.setFunctionsServiceUrl(props.functionsUrl);
            _this.viewModel.restoreFromState(props.state);
        };
        _this.functionService = new HagFunctionService_1.HagFunctionService(props.functionsUrl);
        var termsAndConditionsModal = new ModalViewModel_1.ModalViewModel();
        _this.viewModel = new ProjectViewModel_1.ProjectViewModel(_this.functionService, new ImageLoader_1.ImageLoader(), new SwipeLayoutViewModel_1.SwipeLayoutViewModel(), termsAndConditionsModal, new FootbasesViewModel_1.FootbasesViewModel(), new TipsListViewModel_1.TipsListViewModel(termsAndConditionsModal));
        _this.viewModel.restoreFromState(props.state);
        return _this;
    }
    ProjectView.getInitialProps = function (_a) {
        var req = _a.req, res = _a.res, match = _a.match, history = _a.history, location = _a.location, environmentCode = _a.environmentCode, ctx = __rest(_a, ["req", "res", "match", "history", "location", "environmentCode"]);
        return __awaiter(this, void 0, void 0, function () {
            var functionsUrl, termsconditionsModal, viewModel;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        functionsUrl = resources_1.getHagFunctionUrl(environmentCode);
                        termsconditionsModal = new ModalViewModel_1.ModalViewModel();
                        viewModel = new ProjectViewModel_1.ProjectViewModel(new HagFunctionService_1.HagFunctionService(functionsUrl), new ImageLoader_1.ImageLoader(), new SwipeLayoutViewModel_1.SwipeLayoutViewModel(), termsconditionsModal, new FootbasesViewModel_1.FootbasesViewModel(), new TipsListViewModel_1.TipsListViewModel(termsconditionsModal));
                        return [4 /*yield*/, viewModel.init(match.params.uvMapImagePath, resources_1.getHagStorageUrls(environmentCode))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/, {
                                state: viewModel.getState(),
                                matchUrl: match.url,
                                functionsUrl: functionsUrl
                            }];
                }
            });
        });
    };
    ProjectView.prototype.render = function () {
        var _this = this;
        var _a = this.props, matchUrl = _a.matchUrl, history = _a.history;
        return (React.createElement(React.Fragment, null,
            React.createElement(react_router_1.Route, { path: matchUrl + "/tips", exact: true, render: function () { return React.createElement(TipsListView_1.TipsListView, { tipsListViewModel: _this.viewModel.tipsListViewModel }); } }),
            React.createElement(react_router_1.Route, { path: matchUrl + "/edit", exact: true, render: function () { return React.createElement(ProjectEditorView_1.ProjectEditorView, { matchUrl: matchUrl, viewModel: _this.viewModel }); } }),
            React.createElement(react_router_1.Route, { path: matchUrl + "/download", exact: true, render: function () { return (_this.viewModel.isPatternApplied ?
                    (React.createElement(ProjectDownloadView_1.ProjectDownloadView, { matchUrl: matchUrl, history: history, viewModel: _this.viewModel })) :
                    (React.createElement(react_router_1.Redirect, { to: matchUrl + "/edit" }))); } }),
            React.createElement(react_router_1.Route, { path: matchUrl + "/final", exact: true, render: function () { return (_this.viewModel.isDownloadEnable ?
                    (React.createElement(FinalScreenView_1.FinalScreenView, { matchUrl: matchUrl })) :
                    (React.createElement(react_router_1.Redirect, { to: "/" }))); } })));
    };
    ProjectView = __decorate([
        mobx_react_1.observer
    ], ProjectView);
    return ProjectView;
}(React.Component));
exports.default = ProjectView;
