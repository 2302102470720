"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var FootbaseView_1 = require("./footbase/FootbaseView");
var styles = require("./FootbasesView.scss");
exports.FootbasesView = mobx_react_1.observer(function (_a) {
    var footbasesViewModel = _a.footbasesViewModel;
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: styles.FootbasesHeader }, "Change base colour"),
        React.createElement("div", { className: styles.FootbasesContainer }, footbasesViewModel.footbases.map(function (footbaseViewModel) { return (React.createElement(FootbaseView_1.FootbaseView, { key: footbaseViewModel.name, footbaseViewModel: footbaseViewModel, footbasesViewModel: footbasesViewModel })); }))));
});
