"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var FootbaseViewModel_1 = require("./footbase/FootbaseViewModel");
var FootbasesViewModel = /** @class */ (function () {
    function FootbasesViewModel() {
    }
    FootbasesViewModel.prototype.init = function (footbaseNames, storageUrl) {
        if (!!this.footbases) {
            return;
        }
        this.footbases = footbaseNames.map(function (footbaseName) {
            return new FootbaseViewModel_1.FootbaseViewModel(footbaseName, storageUrl);
        });
        this.selectFootbase(this.footbases[0]);
    };
    FootbasesViewModel.prototype.selectFootbase = function (footbase) {
        this.footbases.forEach(function (o) { return o.deselect(); });
        footbase.select();
        this.selectedFootbaseName = footbase.name;
    };
    __decorate([
        mobx_1.observable
    ], FootbasesViewModel.prototype, "selectedFootbaseName", void 0);
    __decorate([
        mobx_1.action
    ], FootbasesViewModel.prototype, "init", null);
    __decorate([
        mobx_1.action
    ], FootbasesViewModel.prototype, "selectFootbase", null);
    return FootbasesViewModel;
}());
exports.FootbasesViewModel = FootbasesViewModel;
