"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isServer = function () {
    return typeof window === 'undefined';
};
exports.getArrayOfCookieValue = function (cookies, cookieName, separator) {
    if (separator === void 0) { separator = ','; }
    var votedProjectsCookies = cookies.get(cookieName);
    return !votedProjectsCookies ? [] : String(votedProjectsCookies).split(separator);
};
