"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var utils_1 = require("../../utils");
var ProjectViewModel = /** @class */ (function () {
    function ProjectViewModel(hagFunctionService, imageLoader, swipeLayoutViewModel, termsconditionsModal, footbasesViewModel, tipsListViewModel) {
        this.hagFunctionService = hagFunctionService;
        this.imageLoader = imageLoader;
        this.swipeLayoutViewModel = swipeLayoutViewModel;
        this.termsconditionsModal = termsconditionsModal;
        this.footbasesViewModel = footbasesViewModel;
        this.tipsListViewModel = tipsListViewModel;
        this.ratioWidth = 490;
        this.ratioHeight = 700;
        this.isPreviewUpToDate = false;
        this.isPatternApplied = false;
        this.userImageScale = 1;
        this.isCutOutVisible = true;
        this.areTermsAndConditionAccepted = false;
        this.tipsList = [];
        this.maxUserImageSize = 2048;
    }
    Object.defineProperty(ProjectViewModel.prototype, "canUpdatePreview", {
        get: function () {
            return !this.isPreviewUpToDate && !this.isPreviewRenderingInProgress && this.isUserImageLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectViewModel.prototype, "isEditorEnabled", {
        get: function () {
            return !this.isPreviewRenderingInProgress && this.isUserImageLoaded && !this.isUploadInProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectViewModel.prototype, "canAddToGallery", {
        get: function () {
            return !this.isPreviewRenderingInProgress && this.isUserImageLoaded && this.isPreviewUpToDate;
        },
        enumerable: true,
        configurable: true
    });
    ProjectViewModel.prototype.getImageInfo = function (imagePath) {
        var imageNameAndExtension = imagePath.split('.');
        return { name: imageNameAndExtension[0], extension: imageNameAndExtension[1] };
    };
    Object.defineProperty(ProjectViewModel.prototype, "projectImageId", {
        get: function () {
            return this.userImagePath && this.userImagePath.split('.')[0];
        },
        enumerable: true,
        configurable: true
    });
    ProjectViewModel.prototype.setCutOutVisible = function (isCutOutVisible) {
        this.isCutOutVisible = isCutOutVisible;
    };
    ProjectViewModel.prototype.setAreTermsAndConditionAccepted = function (areTermsAndConditionAccepted) {
        this.areTermsAndConditionAccepted = areTermsAndConditionAccepted;
    };
    Object.defineProperty(ProjectViewModel.prototype, "isDownloadEnable", {
        get: function () {
            return this.areTermsAndConditionAccepted;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectViewModel.prototype, "isProjectReadyToDownload", {
        get: function () {
            return !!this.newProjectImagePath;
        },
        enumerable: true,
        configurable: true
    });
    ProjectViewModel.prototype.updateTransforms = function () {
        this.transforms = {
            ratioWidth: this.ratioWidth,
            ratioHeight: this.ratioHeight,
            translateX: this.virtualTranslateX,
            translateY: this.virtualTranslateY,
            scale: this.userImageScale,
            rotate: 0,
            footbaseName: this.footbasesViewModel.selectedFootbaseName
        };
    };
    ProjectViewModel.prototype.init = function (uvMapImagePath, storageUrl) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.uvMapImagePath = uvMapImagePath;
                this.storageUrl = storageUrl;
                this.initializeTipsList();
                return [2 /*return*/];
            });
        });
    };
    ProjectViewModel.prototype.initializeTipsList = function () {
        this.tipsList = [
            {
                imageSrc: this.storageUrl + "/uv-maps/step1.png",
                description: 'Create a fabric design! Use a design program, scan in a freehand drawing, or use a photograph. ' +
                    'You don’t have to be an expert artist – why not browse through your recent photo gallery for inspiration?'
            },
            {
                imageSrc: this.storageUrl + "/uv-maps/step2.png",
                description: 'Make sure your design is a .PNG or .JPG file with a decent resolution (minimum 2Mpixels)'
            },
            {
                imageSrc: this.storageUrl + "/uv-maps/step3.png",
                description: 'Don’t worry too much about making it the right shape, ' +
                    'you will be able to scale and crop the picture after it is uploaded.'
            },
            {
                imageSrc: this.storageUrl + "/uv-maps/step4.png",
                description: 'Make sure that your image is not copyright protected and please, keep it clean! ' +
                    'That means no explicit content. All designs will be processed before being added to the gallery, ' +
                    'and any in breach of these rules will be blocked. (see terms & conditions for detailed guidelines).'
            }
        ];
    };
    ProjectViewModel.prototype.getState = function () {
        return {
            uvMapImagePath: this.uvMapImagePath,
            storageUrl: this.storageUrl
        };
    };
    ProjectViewModel.prototype.restoreFromState = function (state) {
        if (state) {
            this.uvMapImagePath = state.uvMapImagePath;
            this.storageUrl = state.storageUrl;
            this.initializeTipsList();
            this.tipsListViewModel.init(this.tipsList);
            var uvMapImage = this.getImageInfo(this.uvMapImagePath);
            var footbaseNames = [
                uvMapImage.name + "-grey-base",
                uvMapImage.name + "-black-base",
                uvMapImage.name + "-white-base"
            ];
            this.footbasesViewModel.init(footbaseNames, this.storageUrl);
            if (!utils_1.isServer()) {
                this.loadImages();
                this.trackFootbaseChanges();
            }
        }
    };
    ProjectViewModel.prototype.trackFootbaseChanges = function () {
        var _this = this;
        mobx_1.reaction(function () { return _this.footbasesViewModel.selectedFootbaseName; }, function (footbaseName) {
            if (!utils_1.isServer()) {
                var uvMapImage = _this.getImageInfo(_this.uvMapImagePath);
                _this.updateFootbase(footbaseName + "." + uvMapImage.extension);
                _this.transforms.footbaseName = footbaseName;
            }
        });
    };
    ProjectViewModel.prototype.updateFootbase = function (footbasePath) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.imageLoader
                                .loadImage(this.storageUrl + "/uv-maps/" + footbasePath)];
                    case 1:
                        _a.previewBackgroundLayer = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.mergeImageLayers()];
                    case 2:
                        _b.previewImageSrc = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.loadImages = function () {
        return __awaiter(this, void 0, void 0, function () {
            var uvMapImage, uvMapStorageUrl, footbasesUrl, _a, _b, _c, _d;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        uvMapImage = this.getImageInfo(this.uvMapImagePath);
                        uvMapStorageUrl = this.storageUrl + "/uv-maps";
                        footbasesUrl = this.footbasesViewModel.footbases
                            .filter(function (footbase) { return footbase.name !== _this.footbasesViewModel.selectedFootbaseName; })
                            .map(function (footbase) { return _this.storageUrl + "/uv-maps/" + footbase.name + "." + uvMapImage.extension; });
                        return [4 /*yield*/, this.imageLoader.loadImages(footbasesUrl)];
                    case 1:
                        _e.sent();
                        _a = this;
                        return [4 /*yield*/, this.imageLoader
                                .loadImage(uvMapStorageUrl + "/" + this.footbasesViewModel.selectedFootbaseName + "." + uvMapImage.extension)];
                    case 2:
                        _a.previewBackgroundLayer = _e.sent();
                        _b = this;
                        return [4 /*yield*/, this.imageLoader.loadImage(uvMapStorageUrl + "/" + uvMapImage.name + "-multiply." + uvMapImage.extension)];
                    case 3:
                        _b.previewShadowsLayer =
                            _e.sent();
                        _c = this;
                        return [4 /*yield*/, this.imageLoader.loadImage(uvMapStorageUrl + "/" + uvMapImage.name + "-outline.svg")];
                    case 4:
                        _c.previewCutOut =
                            _e.sent();
                        _d = this;
                        return [4 /*yield*/, this.mergeImageLayers()];
                    case 5:
                        _d.previewImageSrc = _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.translateUserImage = function (x, y) {
        this.isPreviewUpToDate = false;
        this.virtualTranslateX = x;
        this.virtualTranslateY = y;
    };
    ProjectViewModel.prototype.updateScale = function (scale) {
        this.isPreviewUpToDate = false;
        this.userImageScale = scale;
    };
    ProjectViewModel.prototype.openUserImageFile = function (userImageFile) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, newUserImage, blobDescriptor;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!userImageFile) {
                            return [2 /*return*/];
                        }
                        this.isPatternApplied = false;
                        this.isUserImageLoaded = false;
                        this.isUploadInProgress = true;
                        this.virtualTranslateX = 0;
                        this.virtualTranslateY = 0;
                        this.userImageScale = 1;
                        return [4 /*yield*/, this.imageLoader.loadImage(userImageFile)];
                    case 1:
                        newUserImage = _b.sent();
                        return [4 /*yield*/, this.resizeImage(newUserImage, userImageFile)];
                    case 2:
                        (_a = _b.sent(), newUserImage = _a.newUserImage, userImageFile = _a.userImageFile);
                        this.userImage = newUserImage;
                        return [4 /*yield*/, this.hagFunctionService.uploadImage(userImageFile)];
                    case 3:
                        blobDescriptor = _b.sent();
                        this.userImagePath = blobDescriptor.name + "." + blobDescriptor.extension;
                        this.isUploadInProgress = false;
                        this.isUserImageLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.resizeImage = function (newUserImage, userImageFile) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(newUserImage.width > this.maxUserImageSize || newUserImage.height > this.maxUserImageSize)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.imageLoader.resize(newUserImage, this.maxUserImageSize)];
                    case 1:
                        userImageFile = _a.sent();
                        return [4 /*yield*/, this.imageLoader.loadImage(userImageFile)];
                    case 2:
                        newUserImage = _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/, { newUserImage: newUserImage, userImageFile: userImageFile }];
                }
            });
        });
    };
    ProjectViewModel.prototype.generate = function () {
        return __awaiter(this, void 0, void 0, function () {
            var project, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        project = {
                            transform: this.transforms,
                            fabricId: this.projectImageId
                        };
                        this.newProjectImagePath = '';
                        this.setVisibleSide('left');
                        return [4 /*yield*/, this.hagFunctionService.generate(project, this.uvMapImagePath, this.userImagePath)];
                    case 1:
                        result = _a.sent();
                        this.newProjectImagePath = result.imagePath;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.applyPattern = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updatePreview()];
                    case 1:
                        _a.sent();
                        this.isPatternApplied = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.setVisibleSide = function (visibleSide) {
        this.swipeLayoutViewModel.swipe(visibleSide);
    };
    ProjectViewModel.prototype.updatePreview = function () {
        return __awaiter(this, void 0, void 0, function () {
            var fileNameAndExtension, uvMapResultBlob, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.swipeLayoutViewModel.visibleSide === 'left') {
                            this.setVisibleSide('right');
                        }
                        this.isPreviewUpToDate = true;
                        this.isPreviewRenderingInProgress = true;
                        this.newProjectImagePath = '';
                        this.updateTransforms();
                        fileNameAndExtension = this.userImagePath.split('.');
                        return [4 /*yield*/, this.hagFunctionService.uvMap(this.uvMapImagePath, fileNameAndExtension[0] + "-md." + fileNameAndExtension[1], this.transforms)];
                    case 1:
                        uvMapResultBlob = _c.sent();
                        _a = this;
                        return [4 /*yield*/, this.imageLoader.loadImage(uvMapResultBlob)];
                    case 2:
                        _a.previewRenderedLayer = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.mergeImageLayers()];
                    case 3:
                        _b.previewImageSrc = _c.sent();
                        this.isPreviewRenderingInProgress = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectViewModel.prototype.resetTransforms = function () {
        this.virtualTranslateX = 0;
        this.virtualTranslateY = 0;
        this.userImageScale = 1;
        this.isPreviewUpToDate = false;
    };
    ProjectViewModel.prototype.mergeImageLayers = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.imageLoader.merge([
                            { image: this.previewBackgroundLayer, compositeOperation: 'source-over' },
                            { image: this.previewRenderedLayer, compositeOperation: 'source-over' },
                            { image: this.previewShadowsLayer, compositeOperation: 'multiply' },
                        ], this.previewBackgroundLayer.width, this.previewBackgroundLayer.height)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isUserImageLoaded", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isUploadInProgress", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isPreviewUpToDate", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isPreviewRenderingInProgress", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isPatternApplied", void 0);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "canUpdatePreview", null);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "isEditorEnabled", null);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "canAddToGallery", null);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "previewImageSrc", void 0);
    __decorate([
        mobx_1.observable.ref
    ], ProjectViewModel.prototype, "userImage", void 0);
    __decorate([
        mobx_1.observable.ref
    ], ProjectViewModel.prototype, "previewRenderedLayer", void 0);
    __decorate([
        mobx_1.observable.ref
    ], ProjectViewModel.prototype, "previewBackgroundLayer", void 0);
    __decorate([
        mobx_1.observable.ref
    ], ProjectViewModel.prototype, "previewShadowsLayer", void 0);
    __decorate([
        mobx_1.observable.ref
    ], ProjectViewModel.prototype, "previewCutOut", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "userImageScale", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "userImagePath", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "uvMapImagePath", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "virtualTranslateX", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "virtualTranslateY", void 0);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isTermOpen", void 0);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "projectImageId", null);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "isCutOutVisible", void 0);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "setCutOutVisible", null);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "areTermsAndConditionAccepted", void 0);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "setAreTermsAndConditionAccepted", null);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "isDownloadEnable", null);
    __decorate([
        mobx_1.computed
    ], ProjectViewModel.prototype, "isProjectReadyToDownload", null);
    __decorate([
        mobx_1.observable
    ], ProjectViewModel.prototype, "newProjectImagePath", void 0);
    __decorate([
        mobx_1.action
    ], ProjectViewModel.prototype, "updateTransforms", null);
    __decorate([
        mobx_1.action
    ], ProjectViewModel.prototype, "loadImages", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "translateUserImage", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "updateScale", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "openUserImageFile", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "generate", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "applyPattern", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "setVisibleSide", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "updatePreview", null);
    __decorate([
        mobx_1.action.bound
    ], ProjectViewModel.prototype, "resetTransforms", null);
    return ProjectViewModel;
}());
exports.ProjectViewModel = ProjectViewModel;
