"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles = require("./JoinContest.scss");
exports.JoinContestView = function () {
    return (React.createElement("div", null,
        React.createElement("h1", { className: styles.Heading }, "Join the contest!"),
        React.createElement("ol", { className: styles.List },
            React.createElement("li", { className: styles.ListItem }, "If you are happy with the result, accept the Terms & Conditions and download your design."),
            React.createElement("li", { className: styles.ListItem },
                "When ready, post it to your Instagram with ",
                React.createElement("strong", null, "#mycapisco"),
                " hashtag."),
            React.createElement("li", { className: styles.ListItem },
                "To take part in the contest follow\u00A0",
                React.createElement("a", { target: "_blank", href: "https://www.instagram.com/flokk_design/?hl=en" }, "H\u00C5G by Flokk"),
                " on Instagram."),
            React.createElement("li", { className: styles.ListItem },
                "It may take up to an hour before your submission shows up in the ",
                React.createElement("a", { href: "/gallery", target: "_blank" }, "gallery"),
                "."),
            React.createElement("li", { className: styles.ListItem },
                "Wait for votes, vote for other projects or submit another design.",
                React.createElement("br", null),
                "You can submit up to 5 designs to increase your chances of winning.",
                React.createElement("br", null),
                React.createElement("strong", null, "Good luck!")))));
};
