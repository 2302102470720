"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var after_1 = require("@jaredpalmer/after");
var routes = [
    {
        path: '/',
        exact: true,
        component: after_1.asyncComponent({
            loader: function () { return Promise.resolve().then(function () { return require('./home/HomeView'); }); },
        }),
    },
    {
        path: '/project/:uvMapImagePath',
        component: after_1.asyncComponent({
            loader: function () { return Promise.resolve().then(function () { return require('./project/ProjectView'); }); },
        }),
    },
    {
        path: '/gallery',
        exact: true,
        component: after_1.asyncComponent({
            loader: function () { return Promise.resolve().then(function () { return require('./gallery/GalleryView'); }); },
        }),
    },
];
exports.default = routes;
