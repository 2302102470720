"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var Logo_1 = require("../Logo/Logo");
var styles = require("./Modal.scss");
var Modal = mobx_react_1.observer(function (_a) {
    var viewModel = _a.viewModel, children = _a.children;
    return (viewModel.isOpen && (React.createElement("div", { className: styles.Modal },
        React.createElement("div", { className: styles.ModalWindow },
            React.createElement("button", { className: styles.ButtonClose, onClick: viewModel.close },
                React.createElement("svg", { width: "27", height: "26", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("g", { fillRule: "nonzero", stroke: "#174E42", strokeWidth: "2", fill: "none" },
                        React.createElement("path", { d: "M24.995 1.21L1.21 24.995M25.83 25.123L1.79 1.081" })))),
            React.createElement("div", { className: styles.ModalContent },
                children,
                React.createElement("div", { className: styles.Logo },
                    React.createElement(Logo_1.default, { size: "small" })))))));
});
exports.default = Modal;
