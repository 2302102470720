"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var react_konva_1 = require("react-konva");
var react_resize_detector_1 = require("react-resize-detector");
var ResponsiveStageViewModel_1 = require("./ResponsiveStageViewModel");
var ResponsiveStage = /** @class */ (function (_super) {
    __extends(ResponsiveStage, _super);
    function ResponsiveStage(props) {
        var _this = _super.call(this, props) || this;
        _this.viewModel = new ResponsiveStageViewModel_1.ResponsiveStageViewModel(props.ratioWidth, props.ratioHeight);
        return _this;
    }
    ResponsiveStage.prototype.render = function () {
        var _this = this;
        var children = this.props.children;
        return (React.createElement(react_resize_detector_1.default, { handleWidth: true }, function (_a) {
            var newWidth = _a.width;
            if (!newWidth) {
                return React.createElement("div", null);
            }
            else {
                var _b = _this.viewModel.getStageSize(newWidth), width = _b.width, height = _b.height;
                _this.viewModel.setStageSize(width, height);
                return (React.createElement(react_konva_1.Stage, { width: _this.viewModel.stageWidth, height: _this.viewModel.stageHeight }, children(_this.viewModel)));
            }
        }));
    };
    ResponsiveStage = __decorate([
        mobx_react_1.observer
    ], ResponsiveStage);
    return ResponsiveStage;
}(React.Component));
exports.ResponsiveStage = ResponsiveStage;
