"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var styles = require("./JoinBanner.scss");
var Button_1 = require("../Button/Button");
var JoinBanner = function (_a) {
    var _b = _a.isGalleryPage, isGalleryPage = _b === void 0 ? false : _b;
    return (React.createElement("section", { className: styles.JoinBanner },
        React.createElement("div", { className: styles.Wrapper },
            React.createElement("h2", { className: styles.Title }, "Join the competition!"),
            React.createElement("div", { className: styles.ButtonsContainer },
                React.createElement("div", { className: styles.ButtonWrapper },
                    React.createElement(Button_1.ButtonLink, { url: '/project/UV1.png/tips', size: "large" }, "Get started")),
                !isGalleryPage &&
                    React.createElement(react_router_dom_1.Link, { className: styles.Link, to: '/gallery' }, "Browse the gallery")))));
};
exports.default = JoinBanner;
