"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var ImageEditorViewModel = /** @class */ (function () {
    function ImageEditorViewModel() {
    }
    ImageEditorViewModel.prototype.getImagePosition = function (imageSize, virtualTranslateX, virtualTranslateY) {
        var x = !virtualTranslateX ? 0 : imageSize.width / virtualTranslateX;
        var y = !virtualTranslateY ? 0 : imageSize.height / virtualTranslateY;
        return { x: x, y: y };
    };
    ImageEditorViewModel.prototype.updateVirtualTranslatePosition = function (imageSize, newPosition, changeImagePosition) {
        var x = newPosition.x, y = newPosition.y;
        var virtualTranslateX = x === 0 ? 0 : imageSize.width / x;
        var virtualTranslateY = y === 0 ? 0 : imageSize.height / y;
        changeImagePosition(virtualTranslateX, virtualTranslateY);
    };
    __decorate([
        mobx_1.action.bound
    ], ImageEditorViewModel.prototype, "getImagePosition", null);
    __decorate([
        mobx_1.action.bound
    ], ImageEditorViewModel.prototype, "updateVirtualTranslatePosition", null);
    return ImageEditorViewModel;
}());
exports.ImageEditorViewModel = ImageEditorViewModel;
