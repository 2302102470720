"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var classNames = require("classnames");
var mobx_react_1 = require("mobx-react");
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var Button_1 = require("../shared/components/Button/Button");
var Checkbox_1 = require("../shared/components/checkbox/Checkbox");
var Modal_1 = require("../shared/components/Modal/Modal");
var SwipeLayout_1 = require("../shared/components/SwipeLayout/SwipeLayout");
var TermsConditions_1 = require("../shared/components/TermsConditions/TermsConditions");
var JoinContestView_1 = require("./joinContest/JoinContestView");
var PreviewView_1 = require("./preview/PreviewView");
var styles = require("./ProjectView.scss");
var ProjectDownloadView = /** @class */ (function (_super) {
    __extends(ProjectDownloadView, _super);
    function ProjectDownloadView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProjectDownloadView.prototype.termsAndConditions = function () {
        return (React.createElement("div", { className: styles.BackText },
            "I accept\u00A0",
            React.createElement("button", { className: classNames(styles.Link, styles.BackText), onClick: this.props.viewModel.termsconditionsModal.open }, "Terms & Conditions")));
    };
    ProjectDownloadView.prototype.leftSide = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: styles.PreviewButton, onClick: function () { return _this.props.viewModel.setVisibleSide('right'); } }, "Preview"),
            React.createElement("div", { className: styles.JoinContestContainer },
                React.createElement(JoinContestView_1.JoinContestView, null),
                React.createElement("div", { className: styles.DownloadButtonSection },
                    React.createElement(Checkbox_1.Checkbox, { checked: this.props.viewModel.areTermsAndConditionAccepted, onChange: this.props.viewModel.setAreTermsAndConditionAccepted, text: this.termsAndConditions() }),
                    this.props.viewModel.isProjectReadyToDownload ?
                        (this.props.viewModel.isDownloadEnable ?
                            React.createElement(Button_1.ButtonALink, { href: this.props.viewModel.newProjectImagePath, target: "_blank", onClick: function () { return _this.props.history.push(_this.props.matchUrl + "/final"); } }, "Download your design") : React.createElement(Button_1.Button, { isDisabled: true }, "Download your design")) :
                        React.createElement(Button_1.ButtonLoader, null, "Preparing your download..."))),
            React.createElement(react_router_dom_1.Link, { className: classNames(styles.Link, styles.LinkToEditor), to: this.props.matchUrl + "/edit" }, "Back to editor")));
    };
    ProjectDownloadView.prototype.rightSide = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: classNames(styles.LinkBack, styles.LinkBackToDownload), onClick: function () { return _this.props.viewModel.setVisibleSide('left'); } }, "Back"),
            React.createElement("div", { className: classNames(styles.PreviewContainer, styles.PreviewInit) },
                React.createElement(PreviewView_1.PreviewView, { previewImageSrc: this.props.viewModel.previewImageSrc }))));
    };
    ProjectDownloadView.prototype.render = function () {
        var viewModel = this.props.viewModel;
        return (React.createElement(React.Fragment, null,
            React.createElement(SwipeLayout_1.SwipeLayout, { viewModel: viewModel.swipeLayoutViewModel, leftSide: this.leftSide(), rightSide: this.rightSide(), leftSideClassName: styles.ProjectDownloadViewLeft }),
            React.createElement(Modal_1.default, { viewModel: viewModel.termsconditionsModal },
                React.createElement(TermsConditions_1.default, null))));
    };
    ProjectDownloadView = __decorate([
        mobx_react_1.observer
    ], ProjectDownloadView);
    return ProjectDownloadView;
}(React.Component));
exports.ProjectDownloadView = ProjectDownloadView;
