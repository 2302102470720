"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classnames_1 = require("classnames");
var react_router_hash_link_1 = require("react-router-hash-link");
var Button_1 = require("../../shared/components/Button/Button");
var styles = require("./Tile.scss");
var Tile = function (_a) {
    var classNameImage = _a.classNameImage, image = _a.image, heading = _a.heading, text = _a.text, linkMoreText = _a.linkMoreText, linkMoreUrl = _a.linkMoreUrl, buttonText = _a.buttonText, buttonUrl = _a.buttonUrl, isButtonFilled = _a.isButtonFilled;
    return (React.createElement("div", { className: styles.Tile },
        React.createElement("div", null,
            React.createElement("img", { className: classnames_1.default(styles.Img, classNameImage), src: image.url, alt: image.alt }),
            React.createElement("h2", { className: styles.Heading }, heading),
            React.createElement("p", { className: styles.Txt }, text),
            linkMoreText && linkMoreUrl && React.createElement(react_router_hash_link_1.HashLink, { className: styles.Link, to: linkMoreUrl, smooth: true }, linkMoreText)),
        React.createElement("div", { className: styles.ButtonWrapper },
            React.createElement(Button_1.ButtonLink, { url: buttonUrl, variant: isButtonFilled ? 'contained' : 'outlined' }, buttonText))));
};
Tile.defaultProps = {
    isButtonFilled: true
};
exports.default = Tile;
