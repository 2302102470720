"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var GoogleAnalytics = /** @class */ (function (_super) {
    __extends(GoogleAnalytics, _super);
    function GoogleAnalytics() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GoogleAnalytics.prototype.componentWillUpdate = function (_a) {
        var location = _a.location, history = _a.history;
        if (location.pathname === this.props.location.pathname) {
            return;
        }
        if (history.action === 'PUSH') {
            // tslint:disable-next-line:no-any
            window.gtag('config', 'UA-4399333-10', {
                'page_title': document.title,
                'page_location': window.location.href,
                'page_path': location.pathname
            });
        }
    };
    GoogleAnalytics.prototype.render = function () {
        return null;
    };
    return GoogleAnalytics;
}(React.Component));
// tslint:disable-next-line:no-any
exports.default = react_router_dom_1.withRouter(GoogleAnalytics);
