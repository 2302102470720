"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var classNames = require("classnames");
var React = require("react");
var styles = require("./CheckboxIcon.scss");
var Icon;
(function (Icon) {
    Icon["eye"] = "eye";
})(Icon = exports.Icon || (exports.Icon = {}));
var iconClasses = {
    'eye': styles.CheckboxEyeIcon,
};
exports.CheckboxIcon = mobx_react_1.observer(function (props) {
    var checked = props.checked, onChange = props.onChange, checkedText = props.checkedText, uncheckedText = props.uncheckedText, icon = props.icon;
    return (React.createElement("div", { className: styles.CheckboxIcon },
        React.createElement("input", { type: "checkbox", className: styles.CheckboxInput, checked: checked, onChange: function () { return onChange(!checked); } }),
        React.createElement("label", { className: classNames(styles.CheckboxText, iconClasses[icon]) }, checked ? checkedText : uncheckedText)));
});
