"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_hash_link_1 = require("react-router-hash-link");
var Logo_1 = require("../Logo/Logo");
var styles = require("./Navigation.scss");
var Navigation = function (_a) {
    var _b = _a.isHomepage, isHomepage = _b === void 0 ? false : _b;
    return (React.createElement("nav", { className: styles.Nav },
        React.createElement(Logo_1.default, null),
        React.createElement("ul", null,
            React.createElement("li", { className: styles.MenuItem },
                React.createElement(react_router_hash_link_1.HashLink, { to: isHomepage ? '#ManualSection' : '/#ManualSection', smooth: true }, "How to enter?")),
            React.createElement("li", { className: styles.MenuItem },
                React.createElement(react_router_hash_link_1.HashLink, { to: isHomepage ? '#AboutHagSection' : '/#AboutHagSection', smooth: true },
                    "About ",
                    React.createElement("span", { className: styles.HideOnMobile }, "H\u00C5G Capisco"))))));
};
exports.default = Navigation;
