"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var mobx_react_1 = require("mobx-react");
var HomeViewModel_1 = require("./HomeViewModel");
var ModalViewModel_1 = require("../shared/components/Modal/ModalViewModel");
var ImageLoader_1 = require("../shared/ImageLoader");
var Header_1 = require("./Header/Header");
var Tile_1 = require("./Tile/Tile");
var Manual_1 = require("./Manual/Manual");
var AboutSection_1 = require("./AboutSection/AboutSection");
var JoinBanner_1 = require("../shared/components/JoinBanner/JoinBanner");
var Footer_1 = require("../shared/components/Footer/Footer");
var styles = require("./HomeView.scss");
var charOutline = require("../../static/images/chair-outline.svg");
var sectionImg = require("../../static/images/office-open-space.jpg");
var chair1 = require("../../static/images/chair-1.png");
var chair2 = require("../../static/images/chair-2.png");
var chair3 = require("../../static/images/chair-3.png");
var chair4 = require("../../static/images/chair-4.png");
var chair5 = require("../../static/images/chair-5.png");
var HomeView = /** @class */ (function (_super) {
    __extends(HomeView, _super);
    function HomeView(props) {
        var _this = _super.call(this, props) || this;
        _this.componentWillReceiveProps = function (props) {
            _this.viewModel.restoreFromState(props.state);
        };
        _this.viewModel = new HomeViewModel_1.HomeViewModel(new ImageLoader_1.ImageLoader(), new ModalViewModel_1.ModalViewModel());
        _this.viewModel.restoreFromState(props.state);
        return _this;
    }
    HomeView.getInitialProps = function () {
        return __awaiter(this, void 0, void 0, function () {
            var viewModel;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        viewModel = new HomeViewModel_1.HomeViewModel(new ImageLoader_1.ImageLoader(), new ModalViewModel_1.ModalViewModel());
                        return [4 /*yield*/, viewModel.init([chair2, chair3, chair4, chair5])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, {
                                state: viewModel.getState()
                            }];
                }
            });
        });
    };
    HomeView.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.HomeView },
                React.createElement(Header_1.default, { areImagesLoaded: this.viewModel.areAnimationImagesLoaded }),
                React.createElement("main", { id: "mainContent" },
                    React.createElement("section", { className: styles.TilesContainer },
                        React.createElement(Tile_1.default, { image: { url: charOutline, alt: '' }, heading: "Create your own design", text: "Upload your fabric design in 1-2-3 easy steps for your chance to win a unique H\u00C5G Capisco featuring your design!", buttonText: "Start designing", buttonUrl: "/project/UV1.png/tips", linkMoreText: "More about entry mechanism", linkMoreUrl: "/#ManualSection" }),
                        React.createElement(Tile_1.default, { classNameImage: styles.TileImg, image: { url: chair1, alt: '' }, heading: "Vote for your favourite", 
                            /* tslint:disable-next-line: max-line-length */
                            text: "Whether you\u2019re looking for inspiration, or want to vote on your favourite designs, browse our gallery of completed entries and pick your winners.", buttonText: "Go to gallery", buttonUrl: "/gallery", isButtonFilled: false })),
                    React.createElement(Manual_1.default, { modalViewModel: this.viewModel.termsconditionsModal }),
                    React.createElement("img", { className: styles.SectionImg, src: sectionImg, alt: "" }),
                    React.createElement(AboutSection_1.default, null),
                    React.createElement(JoinBanner_1.default, null)),
                React.createElement(Footer_1.default, null))));
    };
    HomeView = __decorate([
        mobx_react_1.observer
    ], HomeView);
    return HomeView;
}(React.Component));
exports.default = HomeView;
