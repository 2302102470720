"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var FootbaseViewModel = /** @class */ (function () {
    function FootbaseViewModel(name, storageUrl) {
        this.isSelected = false;
        this.name = name;
        this.url = storageUrl + "/uv-maps/" + name + "-thumbnail.png";
    }
    FootbaseViewModel.prototype.select = function () {
        this.isSelected = true;
    };
    FootbaseViewModel.prototype.deselect = function () {
        this.isSelected = false;
    };
    __decorate([
        mobx_1.observable
    ], FootbaseViewModel.prototype, "isSelected", void 0);
    __decorate([
        mobx_1.action.bound
    ], FootbaseViewModel.prototype, "select", null);
    __decorate([
        mobx_1.action.bound
    ], FootbaseViewModel.prototype, "deselect", null);
    return FootbaseViewModel;
}());
exports.FootbaseViewModel = FootbaseViewModel;
