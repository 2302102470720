"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_react_1 = require("mobx-react");
var React = require("react");
var styles = require("./FootbaseView.scss");
exports.FootbaseView = mobx_react_1.observer(function (_a) {
    var footbaseViewModel = _a.footbaseViewModel, footbasesViewModel = _a.footbasesViewModel;
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { className: styles.Footbase },
            React.createElement("input", { className: styles.RadioInput, type: "radio", value: footbaseViewModel.name, checked: footbaseViewModel.isSelected, onChange: function () { return footbasesViewModel.selectFootbase(footbaseViewModel); } }),
            React.createElement("div", { className: styles.ThumbnailContainer },
                React.createElement("img", { className: styles.Thumbnail, src: footbaseViewModel.url }),
                React.createElement("i", { className: styles.IconCheck })))));
});
